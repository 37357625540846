import { AttributeToPatch } from '../../../services/common.types';
import { BooleanType, NumberType } from '../GenericAttributes.types';

export const VALID_INT_INPUT_KEYS = [
  '-',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'Backspace',
  'Delete',
];
export const VALID_DECIMAL_KEYS = ['.', 'Decimal'];

export const handleFormatDateBeforeChange = (value: Date): string => value?.toISOString();

export const handleFormatNumberBeforeChange = (value: NumberType): NumberType => {
  if (isEmpty(value)) return '';
  return Number(value);
};

export const handleFormatBooleanBeforeChange = (value: string): BooleanType => {
  if (value === '') return value;
  return value === 'true';
};
export const handleFormatBooleanBeforeRender = (value?: boolean): string => {
  if (typeof value !== 'boolean') return value;
  return value ? 'true' : 'false';
};
export const handleFormatListBeforeChange = (value: Array<string> | string): string =>
  Array.isArray(value) ? value.join(',') : value;
export const handleFormatListBeforeRender = (
  value: string,
  attribute: ClassAttribute
): string | string[] => {
  if (!value) return attribute.multipleSelection ? [] : value;
  return attribute.multipleSelection ? value.split(',') : value;
};

export const handleFormatMeasureBeforeRender = (
  value: number,
  attribute: ClassAttribute,
  isEditing: boolean
): string | number => {
  if (isEditing) return value;
  return !isEmpty(value) && !isEmpty(attribute.uom) ? `${value} ${attribute.uom}` : value;
};

export const handleGetLocale = (locale: string): 'pt' | 'es' | 'en' => {
  const localeSerialization = {
    'pt-BR': 'pt',
    'en-US': 'en',
    'es-419': 'es',
  };
  return localeSerialization[locale] ?? localeSerialization['en-US'];
};

export const isEmpty = (value: unknown): boolean =>
  value === null || value === undefined || value === '-' || value === '';

export const handleGetPatchAttributes = (attributes: ClassAttribute[]): AttributeToPatch[] => {
  const result: AttributeToPatch[] = [];

  attributes.forEach((attribute) => {
    if (!isEmpty(attribute.value)) {
      result.push({
        attributeId: attribute.attributeId,
        value: attribute.value,
        uom: attribute.uom,
      });
    }
    if (attribute.type === 'GROUP') {
      const grouppedAttributes = handleGetPatchAttributes(attribute.groupedAttributes);

      if (grouppedAttributes.length > 0)
        result.push({
          attributeId: attribute.attributeId,
          value: attribute.value,
          groupedAttributes: grouppedAttributes,
        });
    }
  });
  return result;
};

export const handleSortOrder = (attributes: ClassAttribute[]): ClassAttribute[] =>
  attributes.sort((el1, el2) => el1.sortOrder - el2.sortOrder);

export const handleFormatAttributes = (attributes: ClassAttribute[]): ClassAttribute[] =>
  attributes.map((el) => ({ ...el, value: el.value ?? '', uom: el.uom ?? '' }));

export const handlePreventKeyboardEventByKeys = (
  event: React.KeyboardEvent<HTMLInputElement>,
  keys: string[]
): void => {
  const key = event.key;
  if (!keys.includes(key)) {
    event.preventDefault();
  }
};

export const handlePreventWhenAlreadyHasKey = (
  event: React.KeyboardEvent<HTMLInputElement>,
  keysToPrevent: string[]
): void => {
  const key = event.key;
  const input = event.currentTarget as HTMLInputElement;
  const value = input.value;

  if (keysToPrevent.includes(key) && value.includes(key)) {
    event.preventDefault();
  }
};

export const handleIntKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
  handlePreventKeyboardEventByKeys(event, VALID_INT_INPUT_KEYS);
  handlePreventWhenAlreadyHasKey(event, ['-']);
};

export const handleNumberKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
  handlePreventKeyboardEventByKeys(event, [...VALID_DECIMAL_KEYS, ...VALID_INT_INPUT_KEYS]);
  const key = event.key;
  const input = event.currentTarget as HTMLInputElement;
  const value = input.value;
  const hasDecimalPoint = value.includes('.');

  handlePreventWhenAlreadyHasKey(event, VALID_DECIMAL_KEYS);
  handlePreventWhenAlreadyHasKey(event, ['-']);

  if (hasDecimalPoint && key >= '0' && key <= '9') {
    const decimalIndex = value.indexOf('.');
    if (value.length - decimalIndex > 2) {
      event.preventDefault();
    }
  }
};

export const handleTransformNumber = (value: NumberType): NumberType => {
  if (!value) return '';
  return Number.isNaN(Number(value)) ? value : Number(value);
};

export const handleTrim = (value: string): string => value.trim();

export const isNumberValid = (value: NumberType): boolean =>
  value === '' || !Number.isNaN(Number(value));

export const isBooleanValid = (value: BooleanType): boolean =>
  value === '' || typeof value === 'boolean';
