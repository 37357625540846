import * as yup from 'yup';
import { BooleanInput } from './components/BooleanInput/BooleanInput';
import DatePickerInput from './components/DatePicker/DatePicker';
import { GroupInput } from './components/Group/GroupInput';
import { ListInput } from './components/ListInput/ListInput';
import { MeasureInput } from './components/MeasureInput/MeasureInput';
import { TextInput } from './components/TextInput/TextInput';
import { BooleanType, NumberType, TFieldsMetadata } from './GenericAttributes.types';
import {
  handleFormatBooleanBeforeChange,
  handleFormatBooleanBeforeRender,
  handleFormatDateBeforeChange,
  handleFormatListBeforeChange,
  handleFormatListBeforeRender,
  handleFormatMeasureBeforeRender,
  handleIntKeyDown,
  handleNumberKeyDown,
  handleTransformNumber,
  handleTrim,
  isBooleanValid,
  isNumberValid,
} from './utils/utils';

export const VALUE_PATH = 'value';
export const UOM_PATH = 'uom';
export const GROUPED_ATTRIBUTES_PATH = 'groupedAttributes';
export const ATTRIBUTE_PATH = 'attributes';
export const INVALID_NUMBER_ERROR = 'Components.INVALID_NUMBER_ERROR';

export const FieldsMetaData: TFieldsMetadata = {
  DATE: {
    schema: yup.string().datetime(),
    Component: DatePickerInput,
    formatValueBeforeChange: handleFormatDateBeforeChange,
  },
  STRING: {
    schema: yup.string().transform(handleTrim),
    Component: TextInput,
  },
  NUMBER: {
    schema: yup
      .mixed<NumberType>()
      .nullable()
      .test('is-number-valid', INVALID_NUMBER_ERROR, isNumberValid)
      .transform(handleTransformNumber),
    Component: TextInput,
    eventHandler: handleNumberKeyDown,
  },
  INT: {
    schema: yup
      .mixed<NumberType>()
      .test('is-number-valid', INVALID_NUMBER_ERROR, isNumberValid)
      .transform(handleTransformNumber),
    Component: TextInput,
    eventHandler: handleIntKeyDown,
  },
  BOOLEAN: {
    schema: yup.mixed<BooleanType>().test('is-number-valid', '', isBooleanValid),
    Component: BooleanInput,
    formatValueBeforeChange: handleFormatBooleanBeforeChange,
    formatValueBeforeRender: handleFormatBooleanBeforeRender,
  },
  LIST: {
    schema: yup.string(),
    Component: ListInput,
    formatValueBeforeChange: handleFormatListBeforeChange,
    formatValueBeforeRender: handleFormatListBeforeRender,
  },
  MEASURE: {
    schema: yup
      .mixed<NumberType>()
      .nullable()
      .test('is-number-valid', INVALID_NUMBER_ERROR, isNumberValid)
      .transform(handleTransformNumber),
    Component: MeasureInput,
    formatValueBeforeRender: handleFormatMeasureBeforeRender,
    eventHandler: handleNumberKeyDown,
  },
  GROUP: {
    schema: yup.mixed(),
    Component: GroupInput,
  },
};
