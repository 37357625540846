import { Card } from '@components';
import { Alert } from '@hexa-ui/components';
import { UseItemInformationFormReturn } from '@hooks/forms/useItemInformationForm/useItemInformationForm';
import { useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { EditionBar } from '../../../../../components/editionBar/EditionBar';

import { EditableStatusField } from '@components/EditionStatusField/EditableStatusField';
import { Status } from '@components/EditionStatusField/EditableStatusField.types';
import { EditableTextField } from '../../EditableTextField/EditableTextField';
import SkuConfirmationModal, {
  SkuConfirmationModalRef,
} from '../SkuConfirmationModal/SkuConfirmationModal';
import { ItemInformationContent, ItemInformationFields } from './ItemInformationForm.styles';

export interface ItemInformationFormProps extends Omit<UseItemInformationFormReturn, 'form'> {
  isEditionDisabled: boolean;
}

const ItemInformationForm = ({
  onSubmit,
  onCancel,
  onEdit,
  isEditing,
  isUpdating,
  isEditionDisabled,
}: ItemInformationFormProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const {
    handleSubmit,
    formState: { defaultValues, isValid },
    getValues,
  } = useFormContext();

  const modalRef = useRef<SkuConfirmationModalRef | null>(null);

  const currentValues = getValues();

  const skuHasChanged = defaultValues?.sku !== currentValues.sku;
  const statusHasChanged = defaultValues?.status !== currentValues.status;

  const valuesAreEquals = useMemo(
    () => JSON.stringify(defaultValues) === JSON.stringify(currentValues),
    [defaultValues, currentValues]
  );

  const isSubmitButtonDisabled = valuesAreEquals || !isValid;

  return (
    <Card
      title={formatMessage({ id: 'ItemDetailsPage.ITEM_INFORMATION' })}
      id="item-details-page-item-information"
      border="medium"
      elevated="small"
      headerAction={
        <EditionBar
          onEdit={onEdit}
          onSubmit={skuHasChanged ? modalRef.current?.onOpen : onSubmit}
          onCancel={onCancel}
          isEditing={isEditing}
          isUpdating={isUpdating && !skuHasChanged}
          isDisabled={isEditionDisabled}
          isSubmitButtonDisabled={isSubmitButtonDisabled && !skuHasChanged}
        />
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ItemInformationContent>
          <ItemInformationFields>
            <EditableTextField
              name="sku"
              title={formatMessage({ id: 'ItemDetailsPage.SKU' })}
              placeholder={formatMessage({ id: 'ItemDetailsPage.SKU_PLACEHOLDER' })}
              isEditing={isEditing}
              showCopyButton
            />
            <EditableTextField
              name="productVariant"
              title={formatMessage({ id: 'ItemDetailsPage.PRODUCT_VARIANT' })}
              isEditing={false}
              disabled
            />
            <EditableTextField
              name="seller"
              title={formatMessage({ id: 'ItemDetailsPage.SELLER' })}
              isEditing={isEditing}
              disabled
            />
            <EditableTextField
              name="sellerId"
              title={formatMessage({ id: 'ItemDetailsPage.SELLER_ID' })}
              tooltip={formatMessage({ id: 'ItemDetailsPage.SELLER_ID_TOOLTIP' })}
              isEditing={isEditing}
              disabled
            />
            <EditableTextField
              name="sellerItemId"
              title={formatMessage({ id: 'ItemDetailsPage.SELLER_ITEM_ID' })}
              tooltip={formatMessage({ id: 'ItemDetailsPage.SELLER_ITEM_ID_TOOLTIP' })}
              isEditing={isEditing}
              disabled
              showCopyButton
            />
            <EditableStatusField
              name="status"
              title={formatMessage({ id: 'ItemDetailsPage.STATUS' })}
              isEditing={isEditing}
              options={{
                ENABLED: formatMessage({ id: 'ItemDetailsPage.ENABLED' }),
                DISABLED: formatMessage({ id: 'ItemDetailsPage.DISABLED' }),
              }}
            />
          </ItemInformationFields>
          {statusHasChanged && currentValues.status === Status.DISABLED && (
            <Alert
              data-testid="status-alert"
              css={{ width: '100%', marginTop: '$6' }}
              type="warning"
              message={formatMessage({ id: 'ItemDetailsPage.STATUS_MESSAGE' })}
            />
          )}
        </ItemInformationContent>
      </form>
      <SkuConfirmationModal ref={modalRef} onSubmit={onSubmit} isUpdating={isUpdating} />
    </Card>
  );
};

export default ItemInformationForm;
