import permissionConstants from '@config/permissionConstants';
import {
  TypeToast,
  usePreferredLanguageV2,
  useToast,
  useUserMetadata,
} from 'admin-portal-shared-services';
import { ALL_COUNTRIES_LIST } from 'config/constants';
import { useStoreMap } from 'effector-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { RouterParams } from 'Router';
import {
  setCountryOptions,
  setIsLoadingCountryOptions,
  setSelectedCountry,
} from 'stores/country/CountryEvents';
import countryStore from 'stores/country/CountryStore';

const DEFAULT_LANGUAGE = 'en-US';

const useCountry = (): UseCountryType => {
  const userCountriesRef = useRef(ALL_COUNTRIES_LIST);
  const [isLoadingCountry, setIsLoadingCountry] = useState(true);

  const { country: countryFromURL } = useParams<RouterParams>();
  const { formatMessage } = useIntl();
  const { data, isLoading: isLoadingMetadata } = useUserMetadata();
  const { preferredLanguage, isLoading: isLoadingLanguage } = usePreferredLanguageV2();
  const toast = useToast();

  const { selectedCountry, countryOptions, isLoadingCountryOptions } = useStoreMap({
    store: countryStore,
    keys: [],
    fn: (state) => ({
      selectedCountry: state.selectedCountry,
      countryOptions: state.countryOptions,
      isLoadingCountryOptions: state.isLoading,
    }),
  });

  const userHasCountriesAll = data?.authorization?.scopes?.includes(
    permissionConstants.CATALOG_COUNTRIES_ALL_SCOPE
  );
  const userSupportedCountries = data?.supportedCountries;
  const userHasSupportedCountries = userSupportedCountries && userSupportedCountries.length > 0;
  const userDoesNotHaveCountriesAllowed = !userHasCountriesAll && !userHasSupportedCountries;

  const isLoadingLanguageOrMetadata = isLoadingLanguage || isLoadingMetadata;

  const countryFromStorage = localStorage?.getItem('catalogAdmin-selectedCountry');
  const formattedCountryFromURL = countryFromURL?.slice(0, 2)?.toUpperCase();

  const isCountryFromStorageSupported = userCountriesRef.current?.includes(countryFromStorage);
  const storageCountry = isCountryFromStorageSupported ? countryFromStorage : undefined;
  const defaultCountry = formattedCountryFromURL || storageCountry || countryOptions[0]?.id;

  const handleSetSelectedCountry = (country: string) => {
    localStorage.setItem('catalogAdmin-selectedCountry', country);
    setSelectedCountry(country);
  };

  useEffect(() => {
    if (!isLoadingLanguageOrMetadata) {
      const userLanguage = preferredLanguage || DEFAULT_LANGUAGE;
      const regionName = new Intl.DisplayNames([userLanguage], { type: 'region' });

      if (!userHasCountriesAll) {
        userCountriesRef.current = userCountriesRef.current.filter((country) =>
          userSupportedCountries.includes(country)
        );
      }

      const translatedCountries = userCountriesRef.current
        .map((country) => ({
          id: country,
          name: regionName.of(country),
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

      setCountryOptions(translatedCountries);
      setIsLoadingCountryOptions(false);
    }
  }, [isLoadingLanguageOrMetadata, preferredLanguage, userHasCountriesAll, userSupportedCountries]);

  useEffect(() => {
    if (!isLoadingCountryOptions) {
      handleSetSelectedCountry(defaultCountry);
      setIsLoadingCountry(false);
    }
  }, [isLoadingCountryOptions, defaultCountry]);

  useEffect(() => {
    if (!isLoadingCountryOptions && userDoesNotHaveCountriesAllowed) {
      toast.notify({
        type: TypeToast.ERROR,
        message: formatMessage({ id: 'UseCountry.NO_SUPPORTED_COUNTRIES' }),
      });
    }
  }, [isLoadingCountryOptions, toast, userDoesNotHaveCountriesAllowed]);

  const memoizedValues = useMemo(
    () => ({
      selectedCountry,
      countryOptions,
      isLoadingCountry,
      setSelectedCountry: handleSetSelectedCountry,
    }),
    [selectedCountry, countryOptions, isLoadingCountry]
  );
  return memoizedValues;
};

export default useCountry;
