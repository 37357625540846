import { getDataTestId } from '@utils/buildDataTestId';
import Paragraph from 'components/typography/Paragraph';
import { useCallback } from 'react';
import { FormatDateOptions, useIntl } from 'react-intl';
import { Box, BoxWrapper, StyledLabel } from './AttributesListReview.styles';

type AttributeValue = boolean | string | string[] | Record<string, string | string[]> | Date;

interface AttributesListReviewProps {
  attributes: Record<string, AttributeValue>;
  groupAttribute?: boolean;
  'data-testid'?: string;
}
const isDate = (obj: any): boolean => {
  return obj instanceof Date && !isNaN(obj.getTime());
};

const renderParagraph = (testId, key, text) => (
  <Paragraph data-testid={getDataTestId(testId, key, 'field')} text={text} />
);

const renderAttributesListReview = (testId, key, value) => (
  <AttributesListReview
    groupAttribute
    attributes={value}
    data-testid={getDataTestId(testId, key)}
  />
);

const getValueType = (value) => {
  if (Array.isArray(value)) return 'array';
  if (typeof value === 'string') return 'string';
  if (typeof value === 'boolean') return 'boolean';
  if (typeof value === 'undefined') return 'undefined';
  if (typeof value === 'object') {
    if (isDate(value)) return 'date';
    if (value.value && value.uom) return 'objectWithValueAndUom';
    if (!value.value && !value.uom) return 'nestedObject';
  }
  return 'unknown';
};

const AttributesListReview = ({
  attributes,
  groupAttribute,
  'data-testid': testId,
}: AttributesListReviewProps): React.ReactElement => {
  const { formatMessage, formatDate } = useIntl();

  const getTextFromStringValue = (value: string): string => {
    if (value === 'true') {
      return formatMessage({ id: 'ProductPage.ATTRIBUTE_YES' });
    } else if (value === 'false') {
      return formatMessage({ id: 'ProductPage.ATTRIBUTE_NO' });
    } else {
      return value || '-';
    }
  };

  const handleFormatDate = useCallback(
    (value: any) => {
      const dateOptions: FormatDateOptions = {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      };

      return formatDate(value, dateOptions);
    },
    [formatDate]
  );

  const renderValue = (testId, key, value) => {
    const valueType = getValueType(value);

    switch (valueType) {
      case 'array':
        return renderParagraph(testId, key, value.length > 0 ? value.join(', ') : '-');
      case 'string':
        return renderParagraph(testId, key, getTextFromStringValue(value));
      case 'boolean':
        return renderParagraph(testId, key, value ? 'Yes' : 'No');
      case 'undefined':
        return renderParagraph(testId, key, '-');
      case 'date':
        return renderParagraph(testId, key, handleFormatDate(value));
      case 'objectWithValueAndUom':
        return renderParagraph(testId, key, `${value.value} ${value.uom}`);
      case 'nestedObject':
        return renderAttributesListReview(testId, key, value);
      default:
        return null;
    }
  };

  return (
    <BoxWrapper groupAttribute={groupAttribute}>
      {attributes &&
        Object.entries(attributes).map(([key, value]: [key: string, value: AttributeValue]) => (
          <Box key={key}>
            <StyledLabel id={key}>{key}</StyledLabel>
            {renderValue(testId, key, value)}
          </Box>
        ))}
    </BoxWrapper>
  );
};

export default AttributesListReview;